<template>
  <div class="counters">
    <div class="counter-block">
      <figure class="counter-block__image">
        <icon
          name="hugeicons:magic-wand-01"
          size="40"
        />
      </figure>
      <h3 class="counter-block__title">
        {{ $t('counters.counter1_title') }}
      </h3>
      <span class="counter-block__text">
        {{ $t('counters.counter1_description') }}
      </span>
    </div>
    <div class="counter-block">
      <figure class="counter-block__image">
        <icon
          name="lucide:party-popper"
          size="40"
        />
      </figure>
      <h3 class="counter-block__title">
        {{ $t('counters.counter2_title') }}
      </h3>
      <span class="counter-block__text">{{ $t('counters.counter2_description') }}</span>
    </div>
    <div class="counter-block">
      <figure class="counter-block__image">
        <icon
          name="fluent:camera-sparkles-16-regular"
          size="40"
        />
      </figure>
      <h3 class="counter-block__title">
        {{ $t('counters.counter3_title') }}
      </h3>
      <span class="counter-block__text">{{ $t('counters.counter3_description') }}</span>
    </div>
    <div class="counter-block">
      <figure class="counter-block__image">
        <icon
          name="material-symbols:star"
          size="40"
        />
      </figure>
      <h3 class="counter-block__title">
        {{ $t('counters.counter4_title') }}
      </h3>
      <span class="counter-block__text">{{ $t('counters.counter4_description') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>
<style lang="scss">
  .counters {
    width: 100%;
    padding: var(--spacing-medium) var(--spacing-default);
    border-top: 2px solid var(--color-secondary);
    border-bottom: 2px solid var(--color-secondary);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;

    @include breakpoint(sm) {
      flex-direction: row;
    }
  }

  .counter-block {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: var(--spacing-medium);

    @include breakpoint(sm) {
      width: 50%;
    }

    @include breakpoint(md) {
      margin-bottom: 0;
      width: 25%;
    }
  }

  .counter-block__title, .counter-block__text {
    text-align: center;
    padding: 0 var(--spacing-default);
  }

  .counter-block__image {
    border-radius: 100%;
    border: 10px solid rgb(255 229 152);
    padding: var(--spacing-default);
    color: var(--color-primary);
    background: var(--color-secondary);
    margin-bottom: var(--spacing-default);
  }
</style>
